html, body {
    width: 100%;
    background-color: #dddddd;
    font-family: Avenir, Optima, Charter, Cochin, Georgia, serif;
}

// ? Theme color
.theme-color {
    color: #ff9900;
}

// ? Font size styling
h4, p, ul {
    font-size: 1.25rem;
}
h5 {
    font-size: 1rem;
}

// ? Anchor tag styling
a {
    color: #0055dd;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

// ? border-radius styling utility
.border-radius-2 {
    border-radius: 0.5rem;
}
.border-radius-3 {
    border-radius: 1rem;
}

// ? Specific styling begins

div#progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 2px;
    width: 0%;
    background-color: #ffffff;
    z-index: 1000;
  
    animation: progress-bar 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--progress) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;
}
@keyframes progress-bar {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

div#invisible-div {
    background-color: #000000;
    color: #ffffff;
    height: 500vh;

    div.container {
        width: 100%;
        background-color: #000000;
        height: 100vh;
        position: sticky;
        position: -webkit-sticky;
        top: 0%;
        font-weight: 500;
        z-index: 100;

        div.row {
            font-size: 18vw;
            div:nth-child(1) {
                flex-grow: 1;
                position: relative;
                justify-content: flex-end;

                animation: dev-text 1s ease infinite;
                animation-play-state: paused;
                animation-delay: calc(var(--scroll) * -1s);
                animation-iteration-count: 1;
                animation-fill-mode: both;

                @keyframes dev-text {
                    40% {
                        left: 0;
                        transform: scale(1, 1);
                    }
                    60% {
                        left: 46vw;
                        transform: scale(0.5, 0.5);
                    }
                    100% {
                        left: 46vw;
                        transform: scale(0.5, 0.5);
                    }
                }

                span {
                    position: relative;
                    white-space: nowrap;

                    span {
                        opacity: 0;

                        animation: em-text 1s ease infinite;
                        animation-play-state: paused;
                        animation-delay: calc(var(--scroll) * -1s);
                        animation-iteration-count: 1;
                        animation-fill-mode: both;

                        @keyframes em-text {
                            55% {
                                opacity: 0;
                            }
                            75% {
                                opacity: 100;
                            }
                            100% {
                                opacity: 100;
                            }
                        }

                        span {
                            color: #ff9900;
                        }
                    }
                }
            }
            div:nth-child(2) {
                span:nth-child(1) {
                    position: absolute;
                    top: 0%;
                  
                    animation: elop-text 1s ease infinite;
                    animation-play-state: paused;
                    animation-delay: calc(var(--scroll) * -1s);
                    animation-iteration-count: 1;
                    animation-fill-mode: both;

                    @keyframes elop-text {
                        20% {
                            top: -20%;
                            opacity: 0;
                        }
                        100% {
                            top: -20%;
                            opacity: 0;
                        }
                    }
                }
                span:nth-child(2) {
                    position: absolute;
                    top: 20%;
                    opacity: 0;
                  
                    animation: ise-text 1s ease infinite;
                    animation-play-state: paused;
                    animation-delay: calc(var(--scroll) * -1s);
                    animation-iteration-count: 1;
                    animation-fill-mode: both;

                    @keyframes ise-text {
                        10% {
                            top: 20%;
                            opacity: 0;
                        }
                        30% {
                            top: 0;
                            opacity: 100;
                        }
                        40% {
                            top: 0;
                            opacity: 100;
                            transform: translate(0, 0) scale(1, 1);
                        }
                        60% {
                            top: 0;
                            opacity: 0;
                            transform: translate(150%, 0) scale(0.5, 0.5);
                        }
                        100% {
                            top: 0;
                            opacity: 0;
                            transform: translate(150%, 0) scale(0.5, 0.5);
                        }
                    }
                }
            }
        }
    }
}

header {
    height: 52px;
    width: 100%;
    font-size: 25px;
    background-color: #000000;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 80;

    span {
        opacity: 0;
        color: #ffffff;
        span {
            color: #ff9900;
        }

        animation: header 1s linear infinite;
        animation-play-state: paused;
        animation-delay: calc(var(--scroll) * -1s);
        animation-iteration-count: 1;
        animation-fill-mode: both;

        @keyframes header {
            92% {
                opacity: 0;
            }
            93% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }
    }
}

section#education table {
    th, td {
        padding: 0.5rem 1rem;
        border-right: 1px solid #aaaaaa;
        white-space: nowrap;
    }
    th:last-child, td:last-child {
        border-right: none;
    }
}

section#howDoIDoIt {
    h3 {
        max-width: calc(100% - 1rem);
    }
    span.fa-plus-circle {
        font-size: 1.5rem;
        transition: transform 0.35s;
        cursor: pointer;

        @media screen and (min-width: 576px) {
            font-size: 2rem;
        }
    }
}

section#experience {
    h3 {
        margin-bottom: 0.125rem;
    }
    .sub-heading {
        color: #555555;
        font-size: 1rem;
        margin-bottom: 0;
    }
    .accordion {
        .card {
            border: none;
            border-radius: 0;

            .card-header {
                background-color: transparent;

                h4 {
                    text-align: left;
                }
            }
        }
        .card:first-child {
            border-radius: 1rem 1rem 0 0;
        }
        .card:last-child {
            border-radius: 0 0 1rem 1rem;
            .card-header {
                border: none;
            }
        }
    }
}

nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 70%;
    width: 25% !important;
    background-color: #555555;
    visibility: hidden;
    opacity: 0;
    padding: 1rem;
    border-radius: 1rem;
    transition: background-color 0.35s;

    animation: nav 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;

    div.row {
        position: relative;
        border-bottom: 1px solid #333333;

        a:not([href]) {
            padding: 0 0.5rem;
            font-size: 1.25rem;
            cursor: pointer;
            color: #dddddd;
            text-decoration: none;
        }
        a:not([href]):hover {
            color: #ffffff;
            font-weight: 500;
        }
        a.active::after {
            content: "";
            position: absolute;
            right: 0.5rem;
            top: calc(50% - 10px);
            border: 5px solid #ff9900;
            border-radius: 5px;
        }
    }
    div.row:last-child {
        border: none;
    } 
}
nav:hover {
    background-color: #000000;
}

div.row.social {
    display: block;
    position: fixed;
    left: 70%;
    bottom: 3rem;
    width: 25%;
    border: none;
    opacity: 0;
    visibility: hidden;

    animation: nav 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;

    a span {
        font-size: 4rem;
        color: #555555;
        transition: color 0.35s;
    }
    .fa-github-square:hover {
        color: #000000;
    }
    .fa-linkedin-square:hover {
        color: #0c66c2;
    }
    .fa-facebook-square:hover {
        color: #1877f2;
    }
}

#floating-menu {
    position: fixed;
    left: 0;
    bottom: 20px;
    visibility: hidden;
    opacity: 0;

    animation: nav 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;

    div.row.main {
        width: 250px;
        height: 50px;
        padding: 5px;
        background-color: #00000099;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        transition: border-radius 0.35s;

        .collapse, .collapsing {
            position: absolute;
            left: 0px;
            bottom: 50px;
            padding: 0;

            nav {
                position: static;
                width: 100% !important;
                visibility: visible;
                opacity: 1;
                background-color: #00000099;
                border-radius: 10px 10px 0 0;
                -webkit-backdrop-filter: blur(25px);
                backdrop-filter: blur(25px);
                animation: none;

                div.row {
                    a:not([href]) {
                        width: 100%;
                        font-size: 1.125rem;
                    }
                    a.active::after {
                        content: "";
                        position: absolute;
                        right: 0.5rem;
                        top: calc(50% - 8px);
                        border: 4px solid #ff9900;
                        border-radius: 4px;
                    }
                }
                div.row:nth-last-child(2) {
                    border-bottom: 1px solid #333333;
                }
            }

            
        }

        span.toggle {
            display: inline-block;
            position: relative;
            width: 50px;
            height: 40px;
            margin-right: 5px;
            svg {
                position: absolute;
                left: 15px;
                width: 20px;
            }
            svg:nth-child(1) {
                top: 14px;
            }
            svg:nth-child(2) {
                bottom: 14px;
            }
        }
        span.toggle::after {
            content: "";
            position: absolute;
            top: -5px;
            left: 55px;
            height: 50px;
            border-right: 1px solid #777777;
        }

        a {
            width: 60px;
            span {
                font-size: 40px;
                color: #dddddd;
            }
        }
        a:nth-last-of-type(3) {
            margin-left: 5px;
        }
    }
    div.row.main.open {
        border-radius: 0 0 10px 10px;

        span.toggle {
            svg:nth-child(1) {
                animation: open-top 0.7s ease both;

                @keyframes open-top {
                    0% {
                        top: 14px;
                        transform: rotate(0deg);
                    }
                    50% {
                        top: 19px;
                        transform: rotate(0deg);
                    }
                    100% {
                        top: 19px;
                        transform: rotate(45deg);
                    }
                }
            }
            svg:nth-child(2) {
                animation: open-bottom 0.7s ease both;

                @keyframes open-bottom {
                    0% {
                        bottom: 14px;
                        transform: rotate(0deg);
                    }
                    50% {
                        bottom: 19px;
                        transform: rotate(0deg);
                    }
                    100% {
                        bottom: 19px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    div.row.main.close {
        transition-delay: 0.35s;
        border-radius: 10px;

        span.toggle {
            svg:nth-child(1) {
                animation: close-top 0.7s ease both;

                @keyframes close-top {
                    0% {
                        top: 19px;
                        transform: rotate(45deg);
                    }
                    50% {
                        top: 19px;
                        transform: rotate(0deg);
                    }
                    100% {
                        top: 14px;
                        transform: rotate(0deg);
                    }
                }
            }
            svg:nth-child(2) {
                animation: close-bottom 0.7s ease both;

                @keyframes close-bottom {
                    0% {
                        bottom: 19px;
                        transform: rotate(-45deg);
                    }
                    50% {
                        bottom: 19px;
                        transform: rotate(0deg);
                    }
                    100% {
                        bottom: 14px;
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}

@keyframes nav {
    99% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 100;
    }
}

// ? For starting the scroll
// body {
//     overflow: hidden;
// }
// #scroll {
//     color: #ffffff;

//     span {
//         position: fixed;
//         bottom: 3%;
//         left: 49%;
//         z-index: 100;
//         font-size: 2.5em;
//         cursor: pointer;
      
//         animation: scroll 1s ease-in-out infinite both;
//         transition: opacity 0.5s ease-out;
//     }
//     @keyframes scroll {
//         0% {
//             bottom: 3%;
//         }
//         50% {
//             bottom: 5%;
//         }
//         100% {
//             bottom: 3%;
//         }
//     }
// }

